<template>
  <div class="footer">
    <ul class="container">
      <li class="menuItem">
        <span @click.stop="goToIntroduce" class="title">Hakkımızda</span>
      </li>
      <li class="menuItem">
        <span @click.stop="goToDestek('0')" class="title">Destek</span>
        <ul class="menuChild">
          <li @click.stop="goToDestek('1')" class="childItem">
            Kullanım Kılavuzları
          </li>
          <li @click.stop="goToDestek('2')" class="childItem">
            Sertifikalar - Uygunluk Beyanı (DoC)
          </li>
          <li @click.stop="goToDestek('3')" class="childItem">
            Yetkili Servis Listesi
          </li>
        </ul>
      </li>
      <li class="menuItem">
        <span @click.stop="goToContact" class="title">İletişim</span>
        <ul class="menuChild">
          <li @click.stop="goToAgreement" class="childItem">
            Privacy Agreement
          </li>
          <li @click.stop="goToTurkeyAgreement" class="childItem">
            Gizlilik Politikası
          </li>
        </ul>
      </li>
    </ul>
    <div class="container staic">
      Gizlilik Kullanım Koşulları Telif Hakkı © 2021 vtech. Tüm hakları
      Saklıdır.
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "myfooter",
  data() {
    return {};
  },
  methods: {
    goToDestek(id) {
      this.$router.push({ path: "/destek", query: { code: id } });
    },
    goToIntroduce() {
      this.$router.push({ path: "/introduce" });
    },
    goToContact() {
      this.$router.push({ path: "/contact" });
    },
    goToAgreement() {
      this.$router.push({ path: "/agreement" });
    },
    goToTurkeyAgreement() {
      this.$router.push({ path: "/turkeyAgreement" });
    },
  },
};
</script>
<style scoped lang="less">
.footer {
  background: #242933;
  padding-top: 30px;
  width: 100%;
  .container {
    list-style: none;
    display: flex;
    padding-left: 0;
    border-bottom: 1px solid lightgray;
    padding-right: 0;
    min-width: 1115px;
    width: 1115px;
    .menuItem {
      font-family: MicrosoftYaHeiSemibold;
      text-align: left;
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 0;
      flex: 1;
    }
    .menuItem:nth-child(2) {
      text-align: center;
      flex: 2;
    }
    .menuItem:nth-child(3) {
      text-align: center;
      flex: 2;
    }
    .menuChild {
      list-style: none;
      padding-left: 43%;
      margin-top: 10px;
      padding-bottom: 50px;
    }
    .childItem {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #adb0b6;
      letter-spacing: 0;
      text-align: left;
      cursor: pointer;
      line-height: 38px;
    }
    .title {
      cursor: pointer;
    }
  }
  .staic {
    font-family: MicrosoftYaHei;
    text-align: left;
    font-size: 12px;
    color: #adb0b6;
    letter-spacing: 0;
    text-align: center;
    line-height: 5;
    border: none;
  }
}
</style>
