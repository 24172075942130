<template>
  <div class="container">
    <header class="header">
      <img class="headerImg" src="@/assets/phone.jpg" alt="" />
    </header>
    <article class="atricle">
      <section class="introduction">
        <label for="" class="label">Hakkımızda</label>
        <div class="content">
          V Tech, bir Türk yerel akıllı telefon üreticisidir (SKD / CKD üretimi). Şirket, müşterilerine olağanüstü bir deneyim sunarak, yüksek teknoloji ve işçilikle müşteriler için yüksek teknoloji ürünler üretmeye kararlıdır. Şirketin vizyonu, sağlıklı, uzun vadeli, müşteri odaklı bir yüksek teknoloji kuruluşu olmak ve dünyanın akıllı yaşamına katkıda bulunmaktır.
        </div>
        <vui-button @click="goToContact" class="atricleButton">devamı</vui-button>
      </section>
    </article>
    <section class="introduction destek">
      <label for="" class="label">Destek</label>
      <ul class="destekList">
        <li @click="goToDestek('1')" class="listItem"><img src="@/assets/kullanım.png" alt="" />Kullanım Kılavuzları</li>
        <li @click="goToDestek('2')" class="listItem"><img src="@/assets/Sertifikalar.png" alt="" />Sertifikalar - Uygunluk Beyanı (DoC)</li>
        <li @click="goToDestek('3')" class="listItem"><img src="@/assets/yetkili.png" alt="" />Yetkili Servis Listesi</li>
      </ul>
    </section>
    <section class="introduction destek telUsContainer">
      <label for="" class="label">İletişim</label>
      <ul class="destekList telUs">
        <li class="telUsItem">
          <img class="telImg" src="@/assets/telphone.png" alt="" />
          <div>
            <div class="tel">0 (800) 621 22 79</div>
            <div class="time">
              09:00 - 18:00 Pazartesi - Pazar Resmi tatiller hariç
            </div>
          </div>
          <img class="right" src="@/assets/right.png" alt="" />
        </li>
        <li class="telUsItem">
          <img class="telImg" src="@/assets/email.png" alt="" />
          <div class="email">
            Bize e-posta gönderin servis@tr.vivo.com
          </div>
          <img class="right" src="@/assets/right.png" alt="" />
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data() {
    return {}
  },
  methods: {
    goToDestek(id) {
      this.$router.push({ path: '/destek', query: { code: id } })
    },
    goToContact() {
      this.$router.push({ path: '/introduce' })
    },
  },
}
</script>
<style scoped lang="less">
.container {
  padding: 0 30px;
  // min-height: calc(100% - 362px);
  min-height: 607px;
  .headerImg {
    width: 100%;
    height: 390px;
    border-radius: 12px;
  }
  .sectionImg {
    width: 298px;
    height: 208px;
    border-radius: 12px;
  }
  .atricle {
    display: flex;
    text-align: left;
    margin: 10px 0;
  }
  .introduction {
    padding: 18px 25px 0;
    background: #f7fafd;
    border-radius: 16px;
    flex: 1;
    height: 190px;
    margin-right: 16px;
    .content {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      height: 57px;
      margin: 20px 0 36px 0px;
      word-wrap: break-word;
    }
    .atricleButton {
      border: 1px solid #242933;
      border-radius: 15px;
      font-family: MicrosoftYaHeiSemibold;
      font-size: 12px;
      color: #242933;
      letter-spacing: 0;
    }
  }
  .label {
    font-family: MicrosoftYaHeiSemibold;
    font-size: 20px;
    color: #7a8793;
    letter-spacing: 0;
  }
  .destek {
    margin: 0;
    text-align: left;
    height: 246px;
    padding-right: 0;
    margin-bottom: 10px;
    .destekList {
      list-style: none;
      display: flex;
    }
    .listItem {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #7a8793;
      letter-spacing: 0;
      cursor: pointer;
      img {
        width: 80px;
        height: 80px;
        margin-bottom: 50px;
      }
    }
  }
  .telUsContainer {
    height: 158px;
  }
  .telImg {
    width: 36px;
    height: 36px;
    margin-right: 12px;
  }
  .telUs {
    padding: 0;
    margin: 36px 0;
  }
  .telUsItem {
    flex: 1;
    display: flex;
    padding-right: 50px;
    div {
      flex: 1;
    }
    .right {
      width: 20px;
      height: 20px;
      margin-top: 8px;
    }
    .tel {
      font-family: MicrosoftYaHeiSemibold;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      font-weight: bold;
    }
    .time {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #7a8793;
      letter-spacing: 0;
      width: 190px;
      word-wrap: break-word;
    }
    .email {
      font-family: MicrosoftYaHeiSemibold;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      line-height: 36px;
    }
  }
}
</style>
