<template>
  <div id="app">
    <header id="nav">
      <div class="container">
        <router-link to="/">VTECH</router-link>
        <ul class="menu">
          <li class="menuItem"><router-link to="/introduce">Hakkımızda</router-link></li>
          <li class="menuItem"><router-link to="/destek">Destek</router-link></li>
          <li class="menuItem"><router-link to="/contact">İletişim</router-link></li>
        </ul>
      </div>
    </header>
    <router-view />
    <footer class="mainFooter">
      <my-footer />
    </footer>
  </div>
</template>
<script>
// @ is an alias to /src
import myFooter from './views/myfooter'
export default {
  name: 'Home',
  components: { myFooter },
}
</script>
<style lang="less">
#app {
  font-family: MicrosoftYaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  min-width: 1170px;
}
#nav {
  width: 100%;
  padding: 20px 0;
  .container {
    display: flex;
    justify-content: space-between;
    a {
      font-family: MicrosoftYaHei-Bold;
      font-size: 24px;
      color: #000000;
      letter-spacing: 0;
      font-weight: bold;
      text-decoration: none;

      &.router-link-exact-active {
        color: #000000;
      }
      &:hover {
        text-decoration: underline;
        text-decoration-color: #000000;
      }
    }
    .menu {
      list-style: none;
      display: flex;
      margin: 0;
      line-height: 2;
      .menuItem {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        margin-left: 30px;
        a {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
  }
}
.container {
  width: 1170px;
  min-width: 1170px;
  margin: auto;
  padding: 0 55px;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mainFooter {
  width: 100%;
}
ul {
  list-style: none;
}
</style>
